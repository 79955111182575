.head-page-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.spiral {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.spiral > svg {
  height: 100%;
  width: 100%;
}

.spread {
  position: absolute;
  filter: blur(150px);
  width: 269px;
  height: 269px;
  display: inline-block;
}

.yellow {
  background: #ffa800;
  left: 0;
  bottom: 0;
  opacity: 0.4;
}

.red {
  background: #f3000b;
  right: 0;
  top: 0;
  opacity: 0.2;
}

.head-nav {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
}

.head-nav > svg {
  cursor: pointer;
}

.content-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 80vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.5em;
}

.head-text {
  font-size: clamp(2em, 5rem, 6rem);
  font-weight: 800;
  animation-name: moveInTop;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}

.head-meta {
  max-width: 800px;
  line-height: 1.7;
  font-weight: 400;
  animation: moveInTop 1s ease-out;
}

.toggle-wrapper {
  background: #fee6e7;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px;
  animation: moveInTop 1s ease-out;
}

.toggle-wrapper > span {
  text-decoration: none;
  color: #f3000b;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 500;
  padding: 3px 15px;
  width: fit-content;
  border-radius: 16px;
  cursor: pointer;
  transition: background 0.3s ease-out;
}

@keyframes moveInTop {
  0% {
    transform: translateY(-200px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(-200px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0.5;
    transform: translatex(400px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 800px) {
  .head-text {
    font-size: 2.5em;
  }
  .content-box {
    row-gap: 2.3em;
  }

  .toggle-wrapper > span {
    font-size: 12px;
  }

  .hide-mobile {
    display: none;
  }
}
@media (max-width: 390px) {
  .head-nav .head-nav-logo{
    width: 60px;
  }
}
