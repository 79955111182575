.prizes-wrapper {
  position: relative;
}

.prizes-wrapper > svg {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
}

.title-text {
  font-weight: 700;
  font-size: 2.5em;
  color: #1c1c1e;
  letter-spacing: -0.02em;
  margin-bottom: 1.4em !important;
}

.prizes-box-flex {
  width: 100%;
  display: flex;
  column-gap: 2.5em;
  align-items: center;
}

.prizes-wrapper > .place-middle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.prizes-box {
  position: relative;
  width: 100%;
  max-width: 370px;
  background: #ffffff;
  border: 1px solid #ebebf0;
  border-radius: 14px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.prizes-box:hover {
  transform: scale(1.07);
}

.prizes-box > .rank {
  color: #1c1c1e;
  font-weight: 600;
  font-size: 15px;
}

.prizes-box > h3 {
  color: #1c1c1e;
  letter-spacing: -0.02em;
  font-weight: 600;
  font-size: 2em;
}

.prizes-box > .cash-prize {
  color: #8e8e93;
  font-size: 12px;
}

.prizes-box > svg {
  position: absolute;
  top: -1.4em;
  right: -2em;
  height: 40px;
  width: 50px;
}

.vpay-bg-gradient {
  border: 1px solid #f3000b;
}

@media (max-width: 800px) {
  .prizes-wrapper > .place-middle {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1em;
  }

  .prizes-box-flex {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1em;
  }

  .prizes-box {
    max-width: 95%;
  }
}
