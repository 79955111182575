.about-dropin {
  width: 100%;
  margin-top: -20px !important;
  margin-bottom: 50px !important;
}

.about-dropin > .flex-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fadeInLeft {
  animation: fadeInLeft 1s ease-out;
}

.flex-wrap > .left-content {
  max-width: 100%;
  width: 50%;
}

.left-content > h3 {
  color: #1c1c1e;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: 17px !important;
}

.left-content > .bold-text {
  color: #3a3a3c;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.7;
  margin-bottom: 17px !important;
}

.left-content > .other-con {
  color: #3a3a3c;
  line-height: 1.7;
  font-size: 15px;
  max-width: 550px;
}

.left-content > .other-con > span {
  margin-top: 17px;
  display: block;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0.7;
    transform: translateX(-300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 800px) {
  .about-dropin {
    margin-top: -100px !important;
  }
  .about-dropin > .flex-wrap {
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .flex-wrap > .left-content {
    width: 100%;
  }

  .flex-wrap > svg {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 380px) {
  .left-content{
    margin-top: 5em;
  }
}
