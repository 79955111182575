body {
	margin: 0;
	height: 100%;
	width: 100%;
	font-size: 16px;
	font-weight: 400;
	font-family: "Plus Jakarta Sans", -apple-system, BlinkMacSystemFont,
		"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
#root {
	height: 100%;
	width: 100%;
}

.container {
	margin: 0 auto;
	max-width: 1200px;
	position: relative;
}

.full-height {
	height: 100%;
}
.full-width {
	width: 100%;
}
.d-flex {
	display: flex;
}
.j-center {
	justify-content: center;
}
.flex-column {
	flex-direction: column;
}
.font-size15em {
	font-size: 1.5em;
}

.align-center {
	align-items: center;
}
.white-bg {
	background: #fff;
}
.mr-8 {
	margin-right: 8px;
}
.ml-8{
	margin-left: 8px;
}
.content-padding {
	padding: 5em 0;
}

.flex-content {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.curved-btn {
	width: 150px;
	padding: 12px 16px;
	background: #f3000b;
	border-radius: 14px;
	font-family: "Plus Jakarta Sans";
	display: inline-block;
}

@media (max-width: 800px) {
	.content-padding {
		padding: 2.5em 0;
	}

	.container {
		padding-left: 20px;
		padding-right: 20px;
	}

	.flex-content {
		flex-direction: column;
		row-gap: 40px;
	}
}
