.bottom-section {
  width: 100%;
}

.bt-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 1.3em;
}

.bt-flex > h2 {
  letter-spacing: -0.02em;
  color: #1c1c1e;
  font-weight: 700;
  font-size: 3em;
  text-align: center;
}
.bt-flex > p {
  color: #1c1c1e;
  line-height: 1.7;
  width: 100%;
  max-width: 559px;
  text-align: center;
  font-size: 15px;
}

@media (max-width: 800px) {
  .bt-flex > h2 {
    font-size: 2em;
  }
}
