.criteria-wrapper {
  background: #fff9ed;
  width: 100%;
}

.criteria-item {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 15px;
}

.criteria-item > h3 {
  color: #12141d;
  font-weight: 700;
  font-size: 20px;
}

.criteria-item > p {
  color: #12141d;
  opacity: 0.7;
  font-size: 16px;
  line-height: 1.7;
  max-width: 380px;
  width: 100%;
}

.criteria-item > a {
  text-decoration: none;
  color: #f3000b;
  font-weight: 500;
}

@media (max-width: 800px) {
  .criteria-item {
    justify-content: center;
  }
}
