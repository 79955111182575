.label-checkbox {
	color: rgba(0, 0, 0, 1);
	font-size: 1.1rem;
	font-weight: 500;
}


.radio{
	display: none;
}
.radio {
	display: block;
	cursor: pointer;
	user-select: none;
	text-align: left;
}
.radio + .radio {
	margin-top: 12px;
}
.radio input {
	display: none;
}
.radio input + span {
	display: inline-block;
	position: relative;
	padding-left: 30px;
}
.radio input + span:before {
	content: "";
	display: block;
	position: absolute;
	top: 0px;
	left: 0px;
	border-radius: 50%;
	margin-right: 5px;
	width: 16px;
	height: 16px;
	border: 1px solid #ccc;
	background: #fff;
}
.radio input + span:after {
	content: "";
	display: block;
	width: 10px;
	height: 10px;
	background: #f3000b;
	position: absolute;
	border-radius: 50%;
	top: 3px;
	left: 3px;
	right: 3px;
	opacity: 0;
	transform: scale(0, 0);
	transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}
.radio input:checked + span:after {
	opacity: 1;
	transform: scale(1, 1);
}

input[type=checkbox] {
	accent-color: #f3000b;
  }