.register-form-header {
	color: #f3000b;
	font-size: 1.6em;
	font-family: "Plus Jakarta Sans";
	font-weight: 500;
	padding: 3px 15px;
	text-align: center;
}
.width75 {
	width: 75%;
}
.label {
	display: block;
	color: rgba(0, 0, 0, 1);
	font-size: 0.9rem;
	font-weight: 500;
}
input::placeholder,
textarea::placeholder {
	display: block;
	color: rgba(32, 14, 50, 0.5);
	font-size: 15px;
}
.submit-btn {
	width: 100%;
	padding: 14px 18px;
	background: #f3000b !important;
	border-radius: 5px;
	font-family: "Plus Jakarta Sans";
	display: inline-block;
}
.text-center {
	text-align: center;
}
textarea {
	resize: none;
	height: 100px;
}

.fade_in {
	animation: fade-in 0.5s ease-in-out;
	transition: all 1.5s ease-in-out;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
